/*.af-view .nav{
    z-index: 99;
    position: fixed!important;
    width: 100%;
}
.af-view .right-content{
    top: 78px!important;
    z-index: 99!important;
}
.af-view .panels{
    padding-top: 80px;
}*/
.add_pathway_div {
  margin-top: 100px;
}
.full.w-bar .right-content {
  padding: unset !important;
}
@media screen and (min-width: 480px) {
  .af-view .sk-footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    background-color: white;
  }
  .af-view .div-block-158 {
    margin-left: 3%;
  }
}
@media screen and (max-width: 479px) {
  .full.w-bar .right-content {
    left: 0px;
  }
  .mentor-closer-block {
    top: auto;
    bottom: 0%;
    left: 50%;
    right: auto;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 767px) {
  .full.w-bar .image-106.right {
    display: block;
  }
  .af-view .right-content {
    z-index: 9 !important;
  }
}
.af-view .nav {
  z-index: 9999 !important;
}
.af-view .div-block-164.profile.open {
  display: block !important;
}
.af-view .div-block-164.profile {
  height: max-content !important;
}
.js-calendar-graph-svg {
  width: 100%;
}
.w-embed > .gcont {
  width: 100% !important;
}
.gcont .width-full .float-right .d-inline-block:first-child {
  margin-left: 10px;
}
.gcont .width-full {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gcont .width-full .float-right .d-inline-block:last-child {
  margin-right: 10px;
}
.gcont > .div-block-33.sp {
  margin-bottom: 5px;
}
.mobile_nav .div-block-163 .div-block-164 {
  left: -100px;
}
.mobile_nav .div-block-164.profile {
  top: 118%;
}

.div-block-630 {
  position: sticky;
  border-bottom: 1px solid #d2d0d0;
  top: 79px;
  z-index: 2;
  background-color: #fff;
}

.secondary_menu {
  top: 124px;
  z-index: 2;
}

.div-block-631 {
  padding-top: 0px;
  top: 196px;
}

.div-block-632 {
  top: 40px;
}

.course_inside_menu {
  margin-top: 0;
  padding-top: 0px;
  display: flex;
  position: fixed;
  overflow: visible;
  top: 174px;
}

.inside_page {
  top: 17px;
}

.secondary_options {
  min-width: unset !important;
}

.secondary_link {
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 0 !important;
}

.link-options-3 {
  margin-right: 0 !important;
}

.div-block-536 .button-5 {
  width: 100%;
}

@media screen and (min-width: 1280px) {
  .button-5 {
    background-color: var(--accent);
  }
}

.panels-3 h1,
.panels-3 h2,
.panels-3 h3,
.panels-3 h4,
.panels-3 h5,
.panels-3 h6 {
  font-size: 16px;
  line-height: 20px;
}

.inside_page.in_tab {
  padding-top: 0 !important;
}

.secondary_link {
  max-width: none;
}

.secondary_menu {
  overflow: auto;
}
