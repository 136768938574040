.svg-tip {
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    color: #bbb;
    font-size: 12px;
    position: absolute;
    z-index: 999999999;
    text-align: center;
    border-radius: 3px;
    width: 240px;
    display: none;

}

.svg-tip:after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    height: 5px;
    width: 5px;
    bottom: -10px;
    margin: 0 0 0 -5px;
    content: " ";
    border: 5px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.8);

}