@import url('../../css/webflow.css');
@import url('../../css/ibl-skillspro-v3.webflow.css');
@import url('../../css/normalize.css');
@import url('../../css/custom.css');

.af-view .chat-box.logged-in.hidden {
  display: flex !important;
}
.dash_nav_item:hover, .dash_nav_item.active{
  border-bottom-color: var(--accent)!important;
}
.d-flex{
  display: flex;
}
.flex-align-center{
  align-items: center;
}
.flex-justify-space-between{
  justify-content: space-between;
}
.d-none{
  display:none;
}
.div-block-161.edit_profile_trigger.home{
  right: 13px;
}