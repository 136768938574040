.footer-menu-container{
    width: auto;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}
.footer-menu-container a{
    text-decoration: unset;
    color: inherit;
    font-size: 14px;
}
.footer-menu-container a:hover{
    text-decoration: underline;
}
.div-block-158{
    height: 100%;
    margin-left: auto!important;
}
.copyright-box{
    display: flex;
    height: 100%;
    align-items: center;
}