.html-submenu{
    position: absolute;
    width: 100%;
    left: 80px;
    top: 70px;
    display: none;
}
.html-submenu iframe{
    border-radius: 10px;
    width: 80%;
    height: 420px;
    border: 0.3px solid grey;
    z-index: 999999;
    position: relative;
}
.dash_nav_item:hover > .html-submenu{
    display: block;
}
